<template>
  <div class="space-y-10">
    <div v-if="use_admin" class="card-main">
      <div class="p-4 space-y-4">
        <h1 class="text-5xl">PotDak</h1>
        <div class="input-main">
          <div class="input-label">GRADE:</div>
          <input class="input-input" v-model="grade_input" />
        </div>
        <div class="flex justify-end w-full">
          <button
            @click="onMakeEgg(grade_input)"
            class="rounded-lg px-4 py-2 bg-indigo-600 text-white"
          >
            MAKE EGG
          </button>
        </div>
      </div>
    </div>
    <div class="card-main bg-white">
      <div class="p-4 space-y-4">
        <div class="flex justify-between space-y-4">
          <h3 class="text-5xl" v-html="$t('#TOOL_CONVERT_DATE_TITLE')"></h3>
        </div>
        <div>
          <img
            class="object-contain w-full h-32 transform -rotate-6"
            src="/img/opensea/date_ui_v2.png"
            alt="date_ui"
          />
        </div>

        <div class="flex justify-start items-center space-x-4">
          <div class="w-16 text-right text-lx">LINK:</div>
          <div class="flex">
            <a
              class="w-full rounded-lg p-2 bg-indigo-600 text-white text-center text-xs"
              href="https://docs.opensea.io/docs/metadata-standards"
              target="_blank"
              >Metadata Standards docs
            </a>
          </div>
        </div>
        <div class="" v-html="$t('#TOOL_CONVERT_DATE_DESC')"></div>
        <div>
          <div class="text-xs" v-html="md(date_sample)"></div>
        </div>
        <div class="input-main">
          <div class="input-label">DATE:</div>
          <input class="input-input" v-model="date_input" />
          <div>➜</div>
          <div class="input-display">{{ date_value }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      date_sample: `
\`\`\`
{
  "display_type": "date", 
  "trait_type": "birthday", 
  "value": 1546360800 ⇐ here
}
\`\`\`
    `.trim(),
      date_input: "2019-01-01",
      grade_input: "5",
      use_admin: false,
    };
  },
  mounted() {
    this.$store.commitRoot("set_ui", this.$route.fullPath);
  },
  computed: {
    date_value: (state) => {
      console.log("this.date_input", state.date_input);
      try {
        const date = moment(state.date_input);
        const diff = date.diff("1970-01-01", "seconds");
        return `${diff}`;
      } catch (err) {
        console.log("err", err);
        return "date ng";
      }
    },
  },
  methods: {
    onMakeEgg(grade_text) {
      console.log("onMakeEgg", grade_text);
    },
  },
};
</script>

<style lang="postcss" scoped>
.card-main {
  @apply space-y-4 overflow-hidden rounded-xl;
}

.input-main {
  @apply flex items-center space-x-4;
}

.input-label {
  @apply w-16 text-right text-xl;
}

.input-input {
  @apply w-32 p-2 rounded-lg shadow border-2 border-gray-300;
}

.input-display {
  @apply w-44 p-2 rounded-lg shadow bg-gray-200 font-bold;
}
</style>
